import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {
    apiGetproblemList
} from "../../../../request/api.js";
export default {
    components: {
        Header,
        Footer,
    }, //添加注册组件事件
    data() {
        return {
            listnum: 0, //搜索的list数量
            searchword: "", //搜索关键字
            page: 1,
            pagesize: 10,
            searchList: [],
            isshowmore: true,
            hotsearch: [],
            noword: "",
            sptitle: true,
            showsearchnum: false, //是否显示搜索关键字以及list的length
            xianshiword: "", //用于搜索之后显示的字
        }
    },
    created() {
        this.getproblem()
    },
    mounted() {

    },
    methods: {
        getproblem(type) {
            apiGetproblemList({
                keyWord: this.searchword,
                page: this.page,
                pageSize: this.pagesize,
            }).then(res => {
                if (res.data.code == 200) {
                    this.xianshiword = this.searchword
                    this.noword = `非常抱歉！没有找到与“${this.searchword}”相关的问题，我们将尽快更新。<br>您也可以通过拨打枫叶客服热线来咨询：`
                        /*快捷问题*/
                    this.hotsearch = res.data.data.keyWords
                    if (res.data.data.qaList.data.length > 0) {
                        let arr = res.data.data.qaList.data
                        this.searchList = this.searchList.concat(arr)
                        this.listnum = res.data.data.qaList.total
                        if (this.searchList.length >= res.data.data.qaList.total) {
                            this.isshowmore = false
                        }
                        //判断是不是通过搜索以及点击请求的方法
                        if (type == 0) {
                            this.showsearchnum = true;
                        }
                        if (this.searchword == "") {
                            this.sptitle = true
                        }
                    }
                    if (res.data.data.qaList.data.length < 10) {
                        this.isshowmore = false
                    }
                }
            });
        },
        search() {
            this.page = 1;
            this.searchList = [];
            this.getproblem(0)
        },
        loadFun() {
            this.page++;
            this.getproblem(0)
        },
        spsearch(item) {
            this.sptitle = false
            this.searchword = item
            this.page = 1;
            this.searchList = [];
            this.getproblem(0)
        }
    },
};